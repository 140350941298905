define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en", {
    "api-errors-messages": {
      "bad-request": "The data entered was not in the correct format.",
      "campaign-creation": {
        "external-user-id-required": "Please specify the type of external user ID which the participants will be required to fill when starting the customised test.",
        "name-required": "Please name your campaign.",
        "purpose-required": "Please select the purpose of your campaign: assess participants or collect their profiles.",
        "target-profile-required": "Please select a target profile for your campaign."
      },
      "default": "The service is temporarily unavailable. Please try again later.",
      "edit-student-number": {
        "student-number-exists": "The student number entered is already used by {firstName} {lastName}"
      },
      "global": "An error occurred. Please try again later.",
      "or-separator": " or ",
      "student-csv-import": {
        "bad-csv-format": "The file must be in csv format with comma or semicolon separator.",
        "encoding-not-supported": "File encoding not supported.",
        "field-bad-values": "Row {line} : The field “{field}” must be “{valids}”.",
        "field-date-format": "Row {line} : The field “{field}” must be in the format dd/mm/yyyy.",
        "field-email-format": "Row {line} : The field “{field}” must be a valid email address.",
        "field-length": "Row {line} : The field “{field}” must contain {limit} characters.",
        "field-max-length": "Row {line} : The field “{field}” must contain less than {limit} characters.",
        "field-min-length": "Row {line} : The field “{field}” must contain at least {limit} characters.",
        "field-required": "Row {line} : The field “{field}” is mandatory.",
        "header-required": "The “{field}” is mandatory.",
        "header-unknown": "Column headers must be identical to the ones on the template.",
        "identifier-unique": "Row {line} : The field “{field}” of this row is repeated several times within the file.",
        "insee-code-invalid": "Row {line} : The field “{field}” is not in the INSEE format.",
        "payload-too-large": "The file must be less than {maxSize}MB.",
        "student-number-format": "Row {line} : The field “{field}” must not contain any special characters.",
        "student-number-unique": "Row {line} : The field “{field}” must be unique within the file."
      },
      "student-xml-import": {
        "empty": "Each student must have an INE and a class.",
        "encoding-not-supported": "File encoding not supported.",
        "ine-required": "INE is mandatory for each student.",
        "ine-unique": "The INE {nationalStudentId} is repeated several times within the file for several students.",
        "invalid-file": "File does not match the requirements.",
        "invalid-file-extension": "File with extension {fileExtension} not supported.",
        "payload-too-large": "The file must be less than {maxSize}MB.",
        "uai-mismatched": "The UAI of your SIECLE file does not match your school."
      }
    },
    "banners": {
      "campaigns": {
        "message": "Don't forget to create your back-to-school campaigns and share the codes with your students before November. More info for '<'a href={middleSchoolDocumentationLink} class={externalLinkClasses} target=\"_blank\" rel=\"noopener noreferrer\"'>'middle schools {faIcon}'</a>' and '<'a href={highSchoolDocumentationLink} class={externalLinkClasses} target=\"_blank\" rel=\"noopener noreferrer\"'>'high schools {faIcon}'</a>'"
      },
      "import": {
        "link-to": "import or reimport the students database",
        "message": "Start of school year: the '<strong>'admin'</strong>' must {linkTo} to start using Uniscieléval. Learn more on '<'a href={middleSchoolDocumentationLink} class={externalLinkClasses} target=\"_blank\" rel=\"noopener noreferrer\"'>'middle schools {faIcon}'</a>' and '<'a href={highSchoolDocumentationLink} class={externalLinkClasses} target=\"_blank\" rel=\"noopener noreferrer\"'>'high schools {faIcon}'</a>'"
      }
    },
    "cards": {
      "participants-average-results": {
        "information": "Find here the average results of your campaign. This includes all the participants who have completed their customised test and sent their results.",
        "title": "Average result"
      },
      "participants-average-stages": {
        "information": "Find here the average rating of your campaign. This includes all the participants who have completed their customised test and sent their results.",
        "title": "Average rating"
      },
      "participants-count": {
        "information": "Find here the total number of participations in your campaign. This includes all the participants who have entered the code and started their customised test or submitted their profile.",
        "loader": "Loading total participants",
        "title": "Total participants"
      },
      "submitted-count": {
        "information": "Find here the results submitted by your participants. This includes all the participants who have finished and clicked on the button \"Submit my results\" or \"Submit my profile\".",
        "loader": "Loading submitted results or profiles",
        "title": "Submitted results",
        "title-profiles": "Submitted profiles"
      }
    },
    "charts": {
      "participants-by-day": {
        "labels-a11y": {
          "date": "Date",
          "shared": "Submitted results",
          "shared-profile": "Submitted profiles",
          "started": "Total participants"
        },
        "labels-legend": {
          "shared": "Submitted results",
          "shared-profile": "Submitted profiles",
          "started": "Total participants"
        },
        "loader": "Loading participant's activity",
        "title": "Participant's activity"
      },
      "participants-by-mastery-percentage": {
        "label-a11y": "From {from, number, ::percent} to {to, number, ::percent}: {count, plural, =0 {0 participant} =1 {1 participant} other {{count, number} participants}}",
        "loader": "Loading participants grouped by success rates",
        "title": "Distribution of participants grouped by success rate",
        "tooltip": {
          "label": "Number of participants : {count}",
          "legend": "{from, number, ::percent} - {to, number, ::percent}",
          "title": "Results {legend}"
        }
      },
      "participants-by-stage": {
        "loader": "Loading and sorting the participants grouped by success rates",
        "participants": "{count, plural, =0 {0 participants} =1 {1 participant} other {{count, number} participants}}",
        "percentage": "{percentage} %",
        "title": "Distribution of participants grouped by success rate"
      },
      "participants-by-status": {
        "labels-a11y": {
          "completed": "{count, plural, =0 {0 participants} =1 {1 participant} other {{count, number} participants}} with pending results",
          "shared": "{count, plural, =0 {0 participants} =1 {1 participant} other {{count, number} participants}} with submitted results",
          "shared-profile": "{count, plural, =0 {0 participants} =1 {1 participant} other {{count, number} participants}} with submitted profiles",
          "started": "{count, plural, =0 {0 participants} =1 {1 participant} other {{count, number} participants}} in progress"
        },
        "labels-legend": {
          "completed-assessment": "Pending results ({count})",
          "completed-assessment-tooltip": "Pending results: These participants have finished their customised test but haven’t submitted their results yet.",
          "completed-profile": "Pending profiles ({count})",
          "completed-profile-tooltip": "Pending profiles: These participants haven’t submitted their profiles yet.",
          "shared": "Submitted results ({count})",
          "shared-profile": "Submitted profiles ({count})",
          "shared-profile-tooltip": "Submitted profiles: These participants have submitted their profiles.",
          "shared-tooltip": "Submitted results: These participants have finished their customised test and submitted their results.",
          "started": "In progress ({count})",
          "started-tooltip": "In progress: These participants haven’t finished their customised test yet."
        },
        "labels-tooltip": {
          "completed-assessment": "Pending results: {percentage, number, ::percent .0}",
          "completed-profile": "Pending profiles: {percentage, number, ::percent .0}",
          "shared": "Submitted results: {percentage, number, ::percent .0}",
          "shared-profile": "Submitted profiles: {percentage, number, ::percent .0}",
          "started": "In progress: {percentage, number, ::percent .0}"
        },
        "loader": "Loading statuses distribution",
        "title": "Statuses"
      }
    },
    "common": {
      "actions": {
        "back": "Back",
        "cancel": "Cancel",
        "close": "Close"
      },
      "filters": {
        "divisions": {
          "empty": "No class",
          "title": "Classes"
        },
        "loading": "Loading..."
      },
      "help-form": "https://pix.org/en-gb/contact-form",
      "home-page": "Uniscieléval home page",
      "loading": "Loading",
      "pagination": {
        "action": {
          "next": "Go to next page",
          "previous": "Go to previous page",
          "select-page-size": "Select the number of items by page"
        },
        "page-info": "{start, number}-{end, number} of {total, plural, =0 {0 items} =1 {1 item} other {{total, number} items}}",
        "page-number": "Page {current, number} / {total, number}",
        "page-results": "{total, plural, =0 {0 items} =1 {1 item} other {{total, number} items}}",
        "result-by-page": "See"
      },
      "percentage": "{value, number, ::percent}"
    },
    "current-lang": "en",
    "navigation": {
      "credits": {
        "number": "{count, plural, =0 {0 credits} =1 {1 credit} other {{count, number} credits}}",
        "tooltip-text": "The number of credits displayed is the number of credits acquired by the organisation and currently valid (regardless of their activation). For more information, please contact us at the following address: <a href=mailto:pro@pix.fr>pro@pix.fr</a>"
      },
      "footer": {
        "a11y": "Accessibility",
        "copyrights": "©",
        "legal-notice": "Legal notice",
        "pix": "Unisciel"
      },
      "main": {
        "campaigns": "Campaigns",
        "certifications": "Certifications",
        "documentation": "Documentation",
        "students-sco": "Students",
        "students-sup": "Students",
        "team": "Team"
      },
      "pix-orga": "Uniscieléval",
      "user-logged-menu": {
        "button": "Open user menu",
        "logout": "Log out"
      }
    },
    "pages": {
      "assessment-individual-results": {
        "badges": "Thematic results",
        "progression": "Progression",
        "result": "Result",
        "stages": {
          "label": "Success rate",
          "value": "{count} out of {total} stars"
        },
        "tab": {
          "results": "Results",
          "review": "Review"
        },
        "table": {
          "column": {
            "competences": "Competences ({count, plural, =0 {-} other {{count}}})",
            "results": {
              "label": "Results",
              "tooltip": "<span class=\"sr-only\">This participant has validated</span> {result, number, ::percent} <span class=\"sr-only\">of the competence {competence}.</span>"
            }
          },
          "empty": "Pending results",
          "row-title": "Competence",
          "title": "Results by competence"
        },
        "title": "Results of {firstName} {lastName}"
      },
      "campaign": {
        "actions": {
          "export-results": "Export the results (.csv)",
          "unarchive": "Unarchive the campaign"
        },
        "archived": "Archived campaign",
        "code": "Code",
        "copy": {
          "code": {
            "default": "Copy code",
            "success": "Copied!"
          },
          "link": {
            "default": "Copy direct link",
            "success": "Copied!"
          }
        },
        "created-by": "Created by",
        "created-on": "Created on",
        "empty-state": "No participants yet! Share with them the following link to join this campaign.",
        "name": "Name of the campaign",
        "tab": {
          "activity": "Activity",
          "results": "Results ({count, number})",
          "review": "Review",
          "settings": "Settings"
        }
      },
      "campaign-activity": {
        "status": {
          "SHARED-assessment": "Submitted results",
          "SHARED-profile": "Submitted profile",
          "STARTED-assessment": "In progress",
          "TO_SHARE-assessment": "Pending results",
          "TO_SHARE-profile": "Pending profile"
        },
        "table": {
          "column": {
            "first-name": "First name",
            "last-name": "Last name",
            "status": "Status"
          },
          "empty": "No participants",
          "row-title": "Participant",
          "title": "List of participants"
        },
        "title": "Activity"
      },
      "campaign-creation": {
        "actions": {
          "create": "Create a campaign"
        },
        "external-id-label": {
          "label": "external user ID label",
          "question-label": "Do you need an external user ID?",
          "suggestion": "Example : \"Student Number\" or \"Email address\" *"
        },
        "landing-page-text": {
          "label": "Text to display on the starting page"
        },
        "legal-warning": "*In accordance with the French law governing computer technology and freedoms (“Informatique et Libertés”), and as data controller, please be careful not to ask for significant or identifying personal data unless it is absolutely necessary. Asking for social security numbers, as well as any sensitive data, is strictly prohibited.",
        "multiple-sendings": {
          "info": "If you choose to allow multiple submissions, the participants will be able to submit their profile several times by re-entering the campaign code. Within Uniscieléval you will find the latest submitted profile.",
          "info-title": "Multiple submissions",
          "question-label": "Do you want to allow participants to submit their profile more than once?"
        },
        "name": {
          "label": "Name of the campaign"
        },
        "no": "No",
        "purpose": {
          "assessment": "Assess participants",
          "assessment-info": "An assessment campaign tests participants on specific topics.",
          "label": "What is the purpose of your campaign?",
          "profiles-collection": "Collect the participants' Unisciel profiles",
          "profiles-collection-info": "A profile collection campaign retrieves the participants’ Unisciel profile: their level for each competence and their Unisciel score."
        },
        "target-profile-informations": "If you want more information, see <a class=\"link\" href=\"https://cloud.pix.fr/s/3joGMGYWSpmHg5w\" target=\"_blank\" rel=\"noopener noreferrer\"> the corresponding documentation</a>.",
        "target-profiles-list": {
          "label": "What would you like to test?"
        },
        "test-title": {
          "label": "Title of the customised test"
        },
        "title": "Create a campaign",
        "yes": "Yes"
      },
      "campaign-individual-results": {
        "shared-date": "Sent on",
        "start-date": "Started on"
      },
      "campaign-individual-review": {
        "title": "Review for {firstName} {lastName}"
      },
      "campaign-modification": {
        "actions": {
          "edit": "Edit"
        },
        "campaign-name": "Name of the campaign",
        "landing-page-text": "Text to display on the starting page",
        "personalised-test-title": "Title of the personalised test",
        "title": "Edit a campaign"
      },
      "campaign-results": {
        "average": "Average results",
        "filters": {
          "actions": {
            "clear": "Clear filters"
          },
          "aria-label": "Filters on participations",
          "participations-count": "{count, plural, =0 {0 participants} =1 {1 participant} other {{count} participants}}",
          "title": "Filters",
          "type": {
            "badges": "Thematic results",
            "divisions": {
              "placeholder": "Classes"
            },
            "groups": {
              "empty": "No group",
              "title": "Groups"
            },
            "stages": "Success rate",
            "status": {
              "empty": "All statuses",
              "title": "Status"
            }
          }
        },
        "result": "Result",
        "table": {
          "column": {
            "badges": "Thematic results",
            "first-name": "First name",
            "last-name": "Last name",
            "results": {
              "label": "Success rate",
              "on-hold": "Pending",
              "under-test": "Test in progress",
              "value": "{percentage, number, ::percent}"
            }
          },
          "empty": "No participants",
          "row-title": "Participant",
          "title": "List of submitted results"
        },
        "tested-items": "Items assessed",
        "title": "Results",
        "validated-items": "Items successfully completed"
      },
      "campaign-review": {
        "description": "According to the target profile selected and the results of your campaign, Unisciel recommends focusing on the following topics, sorted by relevance ({bubble}).",
        "sub-table": {
          "column": {
            "source": {
              "value": "By {source}"
            }
          },
          "row-title": "Tutorial",
          "title": "{count, plural, =1 {1 tutorial} other{{count} tutorials}} recommended by the Unisciel community"
        },
        "sub-title": "Recommendations of topics to focus on",
        "table": {
          "analysis": {
            "column": {
              "relevance": "Relevance",
              "subjects": "Topics ({count, plural, =0 {-} other {{count}}})",
              "tutorial": {
                "aria-label": "Show all tutorials"
              },
              "tutorial-count": {
                "aria-label": "Number of recommended tutorials",
                "value": "{count, plural, =1 {1 tutorial} other {{count} tutorials}}"
              }
            },
            "row-title": "Topic",
            "sort": {
              "relevance": "Sort by relevance"
            },
            "title": "Review by topic"
          },
          "competences": {
            "column": {
              "competences": "Competences ({count})",
              "results": {
                "label": "Results",
                "tooltip": "<span class=\"sr-only\">Participants have validated</span> {result} % <span class=\"sr-only\">of the competence {competence}.</span>"
              }
            },
            "row-title": "Competence",
            "title": "Results by competence"
          },
          "empty": "Pending results"
        },
        "title": "Review"
      },
      "campaign-settings": {
        "actions": {
          "archive": "Archive",
          "edit": "Edit"
        },
        "campaign-type": {
          "assessment": "Assessment campaign",
          "profiles-collection": "Profiles collection campaign",
          "title": "Type of campaign"
        },
        "direct-link": "Direct link",
        "external-user-id-label": "External user ID label",
        "landing-page-text": "Text to display on the starting page",
        "multiple-sendings": {
          "status": {
            "disabled": "No",
            "enabled": "Yes"
          },
          "title": "Multiple Sendings",
          "tooltip": {
            "aria-label": "Description of a multiple sendings campaing",
            "text-multiple-sendings-disabled": "If the multiple submissions is activated, the participants will be able to submit their profile several times by re-entering the campaign code. Within Uniscieléval the latest submitted profile will be displayed.",
            "text-multiple-sendings-enabled": "The participants will be able to submit their profile several times by re-entering the campaign code. Within Uniscieléval you will find the latest submitted profile."
          }
        },
        "personalised-test-title": "Title of the customised test",
        "target-profile": "Target profile",
        "title": "Settings"
      },
      "campaigns-list": {
        "action": {
          "archived": {
            "aria-label": "Display archived campaigns",
            "label": "Archived"
          },
          "create": "Create a campaign",
          "ongoing": {
            "aria-label": "Display ongoing campaigns",
            "label": "Ongoing"
          }
        },
        "no-campaign": {
          "description": "Set up and launch your first campaign. Then, invite your users to join this customised test.",
          "title": "Create your first campaign"
        },
        "table": {
          "column": {
            "campaign": "Campaigns",
            "created-by": "Created by",
            "created-on": "Created on",
            "participants": "Participants",
            "results": "Results submitted"
          },
          "empty": "No campaign",
          "filter": {
            "by-creator": "Search for a creator",
            "by-name": "Search for a campaign"
          },
          "row-title": "Campaign"
        },
        "title": {
          "active": "Active campaigns",
          "archived": "Archived campaigns"
        }
      },
      "certifications": {
        "description": "Please select the class for which you would like to export the certification results (.csv) or download certificates (.pdf).",
        "documentation-link": "https://cloud.pix.fr/s/cRaeKT4ErrXs4X8",
        "documentation-link-label": "Interpreting the results.",
        "documentation-link-notice": "Follow this link to find indications on how to interpret the results: ",
        "download-attestations-button": "Download attestations",
        "download-button": "Export the results",
        "errors": {
          "invalid-division": "The class {selectedDivision} does not exist.",
          "no-certificates": "No certificates for the class {selectedDivision}.",
          "no-results": "No certification results for the class {selectedDivision}."
        },
        "select-label": "Class",
        "title": "Certification results"
      },
      "login": {
        "title": "Log in"
      },
      "login-form": {
        "active-or-retrieve": "Activate or retrieve your Uniscieléval space",
        "email": "Email address",
        "errors": {
          "status": {
            "401": "Missing or invalid credentials",
            "401-should-change-password": "An error occurred. Please change your password.",
            "403": "Access to this Uniscieléval space is limited to invited members. Each Uniscieléval space is managed by an administrator specific to the organisation using it. Please contact your administrator to get invited."
          }
        },
        "forgot-password": "Forgot your password?",
        "forgotten-password-url": "https://app.pix.org/mot-de-passe-oublie?lang=en",
        "hide-password": "Hide password",
        "invitation-already-accepted": "This invitation has already been accepted. Please log in or contact the administrator of your Uniscieléval space.",
        "invitation-was-cancelled": "This invitation is no longer valid. Please contact the administrator of your Uniscieléval space.",
        "is-only-accessible": "Uniscieléval is only accessible to invited members. Please contact your Uniscieléval administrator in order to be invited.",
        "login": "Log in",
        "mandatory-all-fields": "All fields are required.",
        "only-for-admin": "only for school administrations",
        "password": "Password",
        "show-password": "Show password"
      },
      "login-or-register": {
        "login-form": {
          "button": "Log in",
          "title": "Already have an account?"
        },
        "register-form": {
          "button": "Sign up",
          "fields": {
            "button": {
              "label": "Sign up"
            },
            "cgu": {
              "accept": "I agree to the",
              "aria-label": "Accept the terms of use of the Unisciel platform",
              "error": "Please agree to the terms and conditions of use.",
              "link": "https://pix.org/en-gb/terms-and-conditions/",
              "terms-of-use": "terms and conditions of use of the Unisciel platform"
            },
            "email": {
              "error": "Please enter a valid email address.",
              "label": "Email address"
            },
            "first-name": {
              "error": "Please enter a first name.",
              "label": "First name"
            },
            "last-name": {
              "error": "Please enter a last name.",
              "label": "Last name"
            },
            "password": {
              "error": "Your password must contain at least 8 characters and include at least one uppercase letter, one lowercase letter and one number.",
              "label": "Password"
            }
          },
          "legal-text": "The information collected in this form is saved in a computer file by Unisciel to enable access to the service offered by Unisciel. They are kept for the duration of use of the service and are intended for Unisciel. Test results may be communicated to third parties, with your consent, if you have been invited to take a specific customised test. In accordance with the French law governing computer technology and freedoms (“Informatique et Libertés”), you can exercise the right to access and rectify your data by emailing our Data Protection Officer at dpo@Unisciel.fr.",
          "title": "Sign up"
        },
        "title": "You have been invited to join the organisation {organizationName}"
      },
      "preselect-target-profile": {
        "title": "Topic selection"
      },
      "profiles-individual-results": {
        "certifiable": "Eligible for certification",
        "competences-certifiables": "COMP. ELIGIBLE FOR CERTIFICATION",
        "pix": "Unisciel",
        "pix-score": "{score, number}",
        "table": {
          "column": {
            "level": "Level",
            "pix-score": "Unisciel score",
            "skill": "Competence"
          },
          "empty": "Pending results",
          "row-title": "Competence",
          "title": "Results by competence"
        },
        "title": "Profile of {firstName} {lastName}"
      },
      "profiles-list": {
        "table": {
          "column": {
            "certifiable": "Eligible for certification",
            "competences-certifiables": "Competences eligible for certification",
            "first-name": "First name",
            "last-name": "Last name",
            "pix-score": {
              "label": "Unisciel score",
              "value": "{score, number}"
            },
            "sending-date": {
              "label": "Sent on",
              "on-hold": "Pending"
            }
          },
          "empty": "No profiles yet",
          "row-title": "Profile",
          "title": "List of submitted profiles"
        },
        "title": "Submitted profiles"
      },
      "students-sco": {
        "actions": {
          "import-file": {
            "file-type-separator": " or ",
            "label": "Import ({types})"
          },
          "manage-account": "Manage the account",
          "show-actions": "Show actions"
        },
        "connection-types": {
          "email": "Email address",
          "empty": "–",
          "identifiant": "Username",
          "mediacentre": "Mediacentre",
          "none": "None"
        },
        "import": {
          "error-wrapper": "<div>Import has failed.<br/><strong>{message}</strong><br/> Please check or edit your file and try to import it again.</div>",
          "global-error": "<div>Import has failed.<br/>Please try again or contact us through <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"https://pix.org/en-gb/contact-form\">the help form</a>.</div>",
          "global-success": "The list has been successfully imported."
        },
        "manage-authentication-method-modal": {
          "authentication-methods": "Log in methods",
          "copied": "Copied!",
          "error": {
            "default": "An error occurred, our teams are working on finding a solution. Please try again later.",
            "unexpected": "Something went wrong. Please try again."
          },
          "section": {
            "add-username": {
              "button": "Add a username",
              "label": "Add a username log in method"
            },
            "email": {
              "copy": "Copy the email address",
              "label": "Email address"
            },
            "mediacentre": {
              "label": "Mediacentre"
            },
            "password": {
              "copy": "Copy the temporary password",
              "label": "New temporary password",
              "warning-1": "Send this new temporary password to your student.",
              "warning-2": "Your student will be able to log in with the temporary password.",
              "warning-3": "Your student will choose a new password."
            },
            "reset-password": {
              "button": "Reset password",
              "warning": "Resetting will delete the student’s current password."
            },
            "username": {
              "copy": "Copy the username",
              "label": "Username"
            }
          },
          "title": "Managing the student's Unisciel account"
        },
        "table": {
          "column": {
            "date-of-birth": "Date of birth",
            "division": "Class",
            "first-name": "First name",
            "last-name": "Last name",
            "login-method": "Log in method(s)"
          },
          "empty": "No students.",
          "filter": {
            "division": {
              "placeholder": "Search by class"
            },
            "first-name": {
              "aria-label": "Enter a first name",
              "label": "Search by first name"
            },
            "last-name": {
              "aria-label": "Enter a last name",
              "label": "Search by last name"
            },
            "login-method": {
              "aria-label": "Search by log in method",
              "empty-option": "All"
            }
          },
          "row-title": "Student"
        },
        "title": "Students"
      },
      "students-sup": {
        "actions": {
          "download-template": "Download the template",
          "edit-student-number": "Edit the student number",
          "import-file": "Import",
          "show-actions": "Show actions"
        },
        "edit-student-number-modal": {
          "actions": {
            "update": "Update"
          },
          "form": {
            "error": "The student number can't be empty",
            "new-student-number-label": "New student number",
            "student-number": "{firstName} {lastName}'s current student number is: ",
            "success": "{firstName} {lastName}'s student number has been successfully edited."
          },
          "title": "Edit a student number"
        },
        "table": {
          "column": {
            "date-of-birth": "Date of birth",
            "first-name": "First name",
            "group": "Groups",
            "last-name": "Last name",
            "student-number": "Student number"
          },
          "empty": "No students.",
          "filter": {
            "first-name": {
              "aria-label": "Enter a first name",
              "label": "Search by first name"
            },
            "group": {
              "aria-label": "Enter a group",
              "label": "Search by group",
              "selected": "Groups: {groups}"
            },
            "last-name": {
              "aria-label": "Enter a last name",
              "label": "Search by last name"
            },
            "student-number": {
              "aria-label": "Enter a student number",
              "label": "Search by student number"
            }
          },
          "row-title": "Student"
        },
        "title": "Students"
      },
      "students-sup-import": {
        "actions": {
          "add": {
            "details": "Add a list of students to the one already imported and/or edit the information of students already imported.",
            "label": "Add students",
            "title": "Add / Edit"
          },
          "replace": {
            "details": "Import a new list of students in replacement of the existing one.",
            "label": "Overwrite list",
            "title": "Overwrite"
          }
        },
        "description": "What type of import would you like to use?",
        "error-wrapper": "<div>Import has failed.<br/><strong>{message}</strong><br/> Please edit your file and try to import it again.</div>",
        "global-error": "<div>Import has failed.<br/>Please try again or contact us through <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"https://pix.org/en-gb/contact-form\">the help form</a></div>",
        "global-success": "The list has been successfully imported.",
        "global-success-with-warnings": "<div>The list has been successfully imported.<br/><br/>However some values were not recognised:<br/>{warnings}<br/><br/>They have been replaced by \"Not recognised\". If you need other values, please contact us at <a href=\"mailto:sup@pix.fr\">sup@pix.fr</a></div>",
        "supported-formats": "(Supported format: csv)",
        "title": "Import students",
        "warnings": {
          "diploma": "Diplomas unknown: {diplomas}; ",
          "study-scheme": "Study schemes unknown: {studySchemes}; "
        }
      },
      "team-invitations": {
        "cancel-invitation": "Delete this invitation",
        "invitation-cancelled-succeed-message": "The invitation has been deleted.",
        "table": {
          "column": {
            "email-address": "Email address",
            "pending-invitation": "Pending invitation"
          },
          "row": {
            "aria-label": "Pending invitation",
            "message": "Sent on"
          }
        },
        "title": "Team invitations"
      },
      "team-list": {
        "add-member-button": "Invite a member",
        "tabs": {
          "invitation": "Invitations ({count, plural, =0 {-} other {{count}}})",
          "member": "Members ({count, plural, =0 {-} other {{count}}})"
        },
        "title": "My team"
      },
      "team-members": {
        "actions": {
          "edit-organization-membership-role": "Change role",
          "manage": "Manage",
          "remove-membership": "Remove",
          "save": "Save",
          "select-role": {
            "label": "Select a role",
            "options": {
              "admin": "Administrator",
              "member": "Member"
            }
          }
        },
        "notifications": {
          "change-member-role": {
            "error": "An error occurred while changing this member's role.",
            "success": "The role has been changed."
          },
          "remove-membership": {
            "error": "An error occurred while removing this member.",
            "success": "{memberFirstName} {memberLastName} has successfully been removed from your Uniscieléval team."
          }
        },
        "remove-membership-modal": {
          "actions": {
            "remove": "Yes, remove the member"
          },
          "message": "{memberFirstName} {memberLastName} will no longer have access to this Uniscieléval space.<br>The campaigns created by this member will still be accessible to the rest of the team.",
          "title": "Are you sure you want to remove this member?"
        },
        "table": {
          "column": {
            "first-name": "First name",
            "last-name": "Last name",
            "organization-membership-role": "Role"
          },
          "empty": "No members yet",
          "row-title": "Member"
        },
        "title": "Team members"
      },
      "team-new": {
        "email-requirement": "Enter here the email address of the member you want to invite.",
        "errors": {
          "default": "The service is temporarily unavailable. Please try again later.",
          "status": {
            "400": "The email address format is invalid.",
            "404": "This email address doesn't match any Unisciel user.",
            "412": "This member has already been added.",
            "500": "Something went wrong. Please try again."
          }
        },
        "invited-members": "By clicking on the link provided in the invitation, the invited members will be able to create an account or log in with an existing Unisciel account.",
        "several-email-requirement": "Invite several members by separating the email addresses with commas.",
        "success": {
          "invitation": "An invitation has been sent to the email address {email}.",
          "multiple-invitations": "An invitation has been sent to the listed email addresses."
        },
        "title": "Invite a member"
      },
      "team-new-item": {
        "input-label": "Email address(es)",
        "invite-button": "Invite"
      },
      "terms-of-service": {
        "accept": "I accept the terms and conditions",
        "title": "Terms and conditions of use"
      }
    }
  }], ["fr", {
    "api-errors-messages": {
      "bad-request": "Les données que vous avez soumises ne sont pas au bon format.",
      "campaign-creation": {
        "external-user-id-required": "Veuillez préciser le libellé du champ qui sera demandé à vos participants au démarrage du parcours.",
        "name-required": "Veuillez donner un nom à votre campagne.",
        "purpose-required": "Veuillez choisir l’objectif de votre campagne : Évaluation ou Collecte de profils.",
        "target-profile-required": "Veuillez sélectionner un profil cible pour votre campagne."
      },
      "default": "Le service est momentanément indisponible. Veuillez réessayer ultérieurement.",
      "edit-student-number": {
        "student-number-exists": "Le numéro étudiant saisi est déjà utilisé par l’étudiant {firstName} {lastName}"
      },
      "global": "Une erreur est survenue. Veuillez réessayer ultérieurement.",
      "or-separator": " ou ",
      "student-csv-import": {
        "bad-csv-format": "Le fichier doit être au format csv avec séparateur virgule ou point-virgule.",
        "encoding-not-supported": "Encodage du fichier non supporté.",
        "field-bad-values": "Ligne {line} : Le champ “{field}” doit être “{valids}”.",
        "field-date-format": "Ligne {line} : Le champ “{field}” doit être au format jj/mm/aaaa.",
        "field-email-format": "Ligne {line} : Le champ “{field}” doit être une adresse email valide.",
        "field-length": "Ligne {line} : Le champ “{field}” doit faire {limit} caractères.",
        "field-max-length": "Ligne {line} : Le champ “{field}” doit être inférieur à {limit} caractères.",
        "field-min-length": "Ligne {line} : Le champ “{field}” doit être d’au moins {limit} caractères.",
        "field-required": "Ligne {line} : Le champ “{field}” est obligatoire.",
        "header-required": "La colonne “{field}” est obligatoire.",
        "header-unknown": "Les entêtes de colonnes doivent être identiques à celles du modèle.",
        "identifier-unique": "Ligne {line} : Le champ “{field}” de cette ligne est présent plusieurs fois dans le fichier.",
        "insee-code-invalid": "Ligne {line} : Le champ “{field}” n'est pas au format INSEE.",
        "payload-too-large": "La taille du fichier doit être inférieure à {maxSize}Mo.",
        "student-number-format": "Ligne {line} : Le champ “{field}” ne doit pas avoir de caractères spéciaux.",
        "student-number-unique": "Ligne {line} : Le champ “{field}” doit être unique au sein du fichier."
      },
      "student-xml-import": {
        "empty": "Les élèves doivent posséder un INE ainsi qu'une classe.",
        "encoding-not-supported": "Encodage du fichier non supporté.",
        "ine-required": "Le champ INE est obligatoire pour chaque élève.",
        "ine-unique": "L’INE {nationalStudentId} est présent plusieurs fois dans le fichier pour plusieurs élèves.",
        "invalid-file": "Le fichier n’est pas conforme.",
        "invalid-file-extension": "Extension {fileExtension} non supportée.",
        "payload-too-large": "La taille du fichier doit être inférieure à {maxSize}Mo.",
        "uai-mismatched": "L’UAI du fichier SIECLE ne correspond pas à celui de votre établissement."
      }
    },
    "banners": {
      "campaigns": {
        "message": "'<strong>'Parcours de rentrée 2021'</strong>' : N’oubliez pas de créer les campagnes de rentrée et de diffuser les codes aux élèves avant la Toussaint. Plus d’info '<'a href={middleSchoolDocumentationLink} class={externalLinkClasses} target=\"_blank\" rel=\"noopener noreferrer\"'>'collège {faIcon}'</a>' et '<'a href={highSchoolDocumentationLink} class={externalLinkClasses} target=\"_blank\" rel=\"noopener noreferrer\"'>'lycée (GT et Pro) {faIcon}'</a>'"
      },
      "import": {
        "link-to": "importer ou ré-importer la base élèves",
        "message": "Rentrée 2021 : l’'<strong>'administrateur'</strong>' doit {linkTo} pour initialiser Uniscieléval. Plus d’info '<'a href={middleSchoolDocumentationLink} class={externalLinkClasses} target=\"_blank\" rel=\"noopener noreferrer\"'>'collège {faIcon}'</a>' et '<'a href={highSchoolDocumentationLink} class={externalLinkClasses} target=\"_blank\" rel=\"noopener noreferrer\"'>'lycée (GT et Pro) {faIcon}'</a>'"
      }
    },
    "cards": {
      "participants-average-results": {
        "information": "Retrouvez ici la moyenne des résultats de votre campagne. Ce nombre comprend l'ensemble des participants ayant fini leur parcours et envoyé leur résultat.",
        "title": "Résultat moyen"
      },
      "participants-average-stages": {
        "information": "Retrouvez ici le palier moyen de votre campagne. Ce nombre comprend l'ensemble des participants ayant fini leur parcours et envoyé leur résultat.",
        "title": "Palier moyen"
      },
      "participants-count": {
        "information": "Retrouvez ici le nombre de participants total de votre campagne. Ce nombre comprend l'ensemble des participants ayant saisi le code et commencé leur parcours ou leur envoi de profil.",
        "loader": "Chargement du total de participants",
        "title": "Total de participants"
      },
      "submitted-count": {
        "information": "Retrouvez ici les résultats envoyés par vos participants. Ce nombre comprend l’ensemble des participants ayant terminé et cliqué sur le bouton \"J'envoie mes résultats\" ou \"J'envoie mon profil\".",
        "loader": "Chargement des résultats ou profils reçus",
        "title": "Résultats reçus",
        "title-profiles": "Profils reçus"
      }
    },
    "charts": {
      "participants-by-day": {
        "labels-a11y": {
          "date": "Date",
          "shared": "Total des participants ayant envoyé leurs résultats",
          "shared-profile": "Total des participants ayant envoyé leurs profils",
          "started": "Total des participants"
        },
        "labels-legend": {
          "shared": "Résultats reçus",
          "shared-profile": "Profils reçus",
          "started": "Total des participants"
        },
        "loader": "Chargement de l'activité des participants",
        "title": "Activité des participants"
      },
      "participants-by-mastery-percentage": {
        "label-a11y": "De {from, number, ::percent} à {to, number, ::percent}: {count, plural, =0 {0 participant} =1 {1 participant} other {{count, number} participants}}",
        "loader": "Chargement de la répartition des participants par résultat",
        "title": "Répartition des participants par résultat",
        "tooltip": {
          "label": "Nombre de participants : {count}",
          "legend": "{from, number, ::percent} - {to, number, ::percent}",
          "title": "Résultats {legend}"
        }
      },
      "participants-by-stage": {
        "loader": "Chargement de la répartition des participants par paliers",
        "participants": "{count, plural, =0 {0 participant} =1 {1 participant} other {{count, number} participants}}",
        "percentage": "{percentage} %",
        "title": "Répartition des participants par paliers"
      },
      "participants-by-status": {
        "labels-a11y": {
          "completed": "{count, plural, =0 {0 participant} =1 {1 participant} other {{count, number} participants}} en attente d'envoi",
          "shared": "{count, plural, =0 {0 participant} =1 {1 participant} other {{count, number} participants}} ayant envoyés leurs résultats",
          "shared-profile": "{count, plural, =0 {0 participant} =1 {1 participant} other {{count, number} participants}} ayant envoyés leurs profils",
          "started": "{count, plural, =0 {0 participant} =1 {1 participant} other {{count, number} participants}} en cours de test"
        },
        "labels-legend": {
          "completed-assessment": "En attente d'envoi ({count})",
          "completed-assessment-tooltip": "En attente d’envoi : Ces participants ont terminé leur parcours mais n’ont pas encore envoyé leurs résultats.",
          "completed-profile": "En attente d'envoi ({count})",
          "completed-profile-tooltip": "En attente d’envoi : Ces participants n’ont pas encore envoyé leurs profils.",
          "shared": "Résultats reçus ({count})",
          "shared-profile": "Profils reçus ({count})",
          "shared-profile-tooltip": "Profils reçus : Ces participants ont envoyé leurs profils.",
          "shared-tooltip": "Résultats reçus : Ces participants ont terminé leur parcours et envoyé leurs résultats.",
          "started": "En cours ({count})",
          "started-tooltip": "En cours : Ces participants n’ont pas encore terminé leur parcours."
        },
        "labels-tooltip": {
          "completed-assessment": "En attente d'envoi : {percentage, number, ::percent .0}",
          "completed-profile": "En attente d'envoi : {percentage, number, ::percent .0}",
          "shared": "Résultats reçus : {percentage, number, ::percent .0}",
          "shared-profile": "Profils reçus : {percentage, number, ::percent .0}",
          "started": "En cours : {percentage, number, ::percent .0}"
        },
        "loader": "Chargement des proportions par statut",
        "title": "Statuts"
      }
    },
    "common": {
      "actions": {
        "back": "Retour",
        "cancel": "Annuler",
        "close": "Fermer"
      },
      "filters": {
        "divisions": {
          "empty": "Aucune classe",
          "title": "Classes"
        },
        "loading": "Chargement..."
      },
      "help-form": "https://support.pix.fr/support/tickets/new",
      "home-page": "Page d'accueil de Uniscieléval",
      "loading": "Chargement en cours",
      "pagination": {
        "action": {
          "next": "Aller à la page suivante",
          "previous": "Aller à la page précédente",
          "select-page-size": "Sélectionner une pagination"
        },
        "page-info": "{start, number}-{end, number} sur {total, plural, =0 {0 élément} =1 {1 élément} other {{total, number} éléments}}",
        "page-number": "Page {current, number} / {total, number}",
        "page-results": "{total, plural, =0 {0 élément} =1 {1 élément} other {{total, number} éléments}}",
        "result-by-page": "Voir"
      },
      "percentage": "{value, number, ::percent}"
    },
    "current-lang": "fr",
    "navigation": {
      "credits": {
        "number": "{count, plural, =0 {0 crédit} =1 {1 crédit} other {{count, number} crédits}}",
        "tooltip-text": "Le nombre de crédits affichés correspond au nombre de crédits acquis par l’organisation et en cours de validité (indépendamment de leur activation). Pour plus d’information contactez-nous à l’adresse <a href=mailto:pro@pix.fr>pro@pix.fr</a>"
      },
      "footer": {
        "a11y": "Accessibilité : non conforme",
        "copyrights": "©",
        "legal-notice": "Mentions légales",
        "pix": "Unisciel"
      },
      "main": {
        "campaigns": "Campagnes",
        "certifications": "Certifications",
        "documentation": "Documentation",
        "students-sco": "Élèves",
        "students-sup": "Étudiants",
        "team": "Équipe"
      },
      "pix-orga": "Uniscieléval",
      "user-logged-menu": {
        "button": "Ouvrir le menu utilisateur",
        "logout": "Se déconnecter"
      }
    },
    "pages": {
      "assessment-individual-results": {
        "badges": "Résultats Thématiques",
        "progression": "Avancement",
        "result": "Résultat",
        "stages": {
          "label": "Paliers",
          "value": "{count} étoiles sur {total}"
        },
        "tab": {
          "results": "Résultats",
          "review": "Analyse"
        },
        "table": {
          "column": {
            "competences": "Compétences ({count, plural, =0 {-} other {{count}}})",
            "results": {
              "label": "Résultats",
              "tooltip": "<span class=\"sr-only\">Ce participant a validé</span> {result, number, ::percent} <span class=\"sr-only\">de la compétence {competence}.</span>"
            }
          },
          "empty": "En attente de résultats",
          "row-title": "Compétence",
          "title": "Résultats par compétence"
        },
        "title": "Résultats de {firstName} {lastName}"
      },
      "campaign": {
        "actions": {
          "export-results": "Exporter les résultats (.csv)",
          "unarchive": "Désarchiver la campagne"
        },
        "archived": "Campagne archivée",
        "code": "Code",
        "copy": {
          "code": {
            "default": "Copier le code",
            "success": "Copié !"
          },
          "link": {
            "default": "Copier le lien direct",
            "success": "Copié !"
          }
        },
        "created-by": "Créée par",
        "created-on": "Créée le",
        "empty-state": "Aucun participant pour l’instant ! Envoyez-leur le lien suivant pour rejoindre votre campagne.",
        "name": "Nom de la campagne",
        "tab": {
          "activity": "Activité",
          "results": "Résultats ({count, number})",
          "review": "Analyse",
          "settings": "Paramètres"
        }
      },
      "campaign-activity": {
        "status": {
          "SHARED-assessment": "Résultats reçus",
          "SHARED-profile": "Profil reçu",
          "STARTED-assessment": "En cours",
          "TO_SHARE-assessment": "En attente d'envoi",
          "TO_SHARE-profile": "En attente d'envoi"
        },
        "table": {
          "column": {
            "first-name": "Prénom",
            "last-name": "Nom",
            "status": "Statut"
          },
          "empty": "Aucun participant",
          "row-title": "Participant",
          "title": "Liste des participants"
        },
        "title": "Activité"
      },
      "campaign-creation": {
        "actions": {
          "create": "Créer la campagne"
        },
        "external-id-label": {
          "label": "Libellé de l’identifiant",
          "question-label": "Souhaitez-vous demander un identifiant externe ?",
          "suggestion": "Exemple: \"Numéro de l'étudiant\" ou \"Adresse e-mail professionnelle\" *"
        },
        "landing-page-text": {
          "label": "Texte de la page d'accueil"
        },
        "legal-warning": "* En vertu de la loi Informatique et libertés, et en tant que responsable de traitement, soyez attentifs à ne pas demander de donnée particulièrement identifiante ou signifiante si ce n’est pas absolument indispensable. Le numéro de sécurité sociale (NIR) est à proscrire ainsi que toute donnée sensible.",
        "multiple-sendings": {
          "info": "Si vous choisissez l’envoi multiple, le participant pourra envoyer plusieurs fois son profil en saisissant à nouveau le code campagne. Au sein de Uniscieléval, vous trouverez le dernier profil envoyé.",
          "info-title": "Envoi multiple",
          "question-label": "Souhaitez-vous permettre aux participants d'envoyer plusieurs fois leur profil ?"
        },
        "name": {
          "label": "Nom de la campagne"
        },
        "no": "Non",
        "purpose": {
          "assessment": "Évaluer les participants",
          "assessment-info": "Une campagne d’évaluation permet de tester les participants sur des sujets précis.",
          "label": "Quel est l'objectif de votre campagne ?",
          "profiles-collection": "Collecter les profils Unisciel des participants",
          "profiles-collection-info": "Une campagne de collecte de profils permet de récupérer le profil des participants : niveaux par compétence et score Unisciel."
        },
        "target-profile-informations": "Si vous souhaitez avoir plus d'information, consulter <a class=\"link\" href=\"https://cloud.pix.fr/s/3joGMGYWSpmHg5w\" target=\"_blank\" rel=\"noopener noreferrer\"> la documentation correspondante</a>.",
        "target-profiles-list": {
          "label": "Que souhaitez-vous tester ?"
        },
        "test-title": {
          "label": "Titre du parcours"
        },
        "title": "Création d'une campagne",
        "yes": "Oui"
      },
      "campaign-individual-results": {
        "shared-date": "Envoyé le",
        "start-date": "Commencé le"
      },
      "campaign-individual-review": {
        "title": "Analyse pour {firstName} {lastName}"
      },
      "campaign-modification": {
        "actions": {
          "edit": "Modifier"
        },
        "campaign-name": "Nom de la campagne",
        "landing-page-text": "Texte de la page d'accueil",
        "personalised-test-title": "Titre du parcours",
        "title": "Modification d'une campagne"
      },
      "campaign-results": {
        "average": "Moyenne des résultats",
        "filters": {
          "actions": {
            "clear": "Effacer les filtres"
          },
          "aria-label": "Filtres sur les participations",
          "participations-count": "{count, plural, =0 {0 participant} =1 {1 participant} other {{count} participants}}",
          "title": "Filtres",
          "type": {
            "badges": "Thématiques",
            "divisions": {
              "placeholder": "Classes"
            },
            "groups": {
              "empty": "Aucun groupe",
              "title": "Groupes"
            },
            "stages": "Paliers",
            "status": {
              "empty": "Tous les statuts",
              "title": "Statut"
            }
          }
        },
        "result": "Résultat",
        "table": {
          "column": {
            "badges": "Résultats Thématiques",
            "first-name": "Prénom",
            "last-name": "Nom",
            "results": {
              "label": "Résultats",
              "on-hold": " En attente d'envoi",
              "under-test": "En cours de test",
              "value": "{percentage, number, ::percent}"
            }
          },
          "empty": "Aucune participation",
          "row-title": "Participant",
          "title": "Liste des résultats reçus"
        },
        "tested-items": "Acquis évalués",
        "title": "Résultats",
        "validated-items": "Acquis validés"
      },
      "campaign-review": {
        "description": "En fonction du référentiel testé et des résultats de la campagne, Unisciel vous recommande ces sujets à travailler, classés par degré de pertinence ({bubble}).",
        "sub-table": {
          "column": {
            "source": {
              "value": "Par {source}"
            }
          },
          "row-title": "Tutoriel",
          "title": "{count, plural, =1 {1 tuto recommandé} other{{count} tutos recommandés}} par la communauté Unisciel"
        },
        "sub-title": "Recommandation de sujets à travailler",
        "table": {
          "analysis": {
            "column": {
              "relevance": "Pertinence",
              "subjects": "Sujets ({count, plural, =0 {-} other {{count}}})",
              "tutorial": {
                "aria-label": "Afficher la liste des tutos"
              },
              "tutorial-count": {
                "aria-label": "Nombre de tutoriels recommandés",
                "value": "{count, plural, =1 {1 tuto} other {{count} tutos}}"
              }
            },
            "row-title": "Sujet",
            "sort": {
              "relevance": "Trier par pertinence"
            },
            "title": "Analyse par sujet"
          },
          "competences": {
            "column": {
              "competences": "Compétences ({count})",
              "results": {
                "label": "Résultats",
                "tooltip": "<span class=\"sr-only\">Les participants ont validé</span> {result} % <span class=\"sr-only\">de la compétence {competence}.</span>"
              }
            },
            "row-title": "Compétence",
            "title": "Résultats par compétence"
          },
          "empty": "En attente de résultats"
        },
        "title": "Analyse"
      },
      "campaign-settings": {
        "actions": {
          "archive": "Archiver",
          "edit": "Modifier"
        },
        "campaign-type": {
          "assessment": "Campagne d'évaluation",
          "profiles-collection": "Campagne de collecte de profils",
          "title": "Type de la campagne"
        },
        "direct-link": "Lien direct",
        "external-user-id-label": "Libellé de l'identifiant",
        "landing-page-text": "Texte de la page d'accueil",
        "multiple-sendings": {
          "status": {
            "disabled": "Non",
            "enabled": "Oui"
          },
          "title": "Envoi multiple",
          "tooltip": {
            "aria-label": "Description de la campagne d'envoi multiple",
            "text-multiple-sendings-disabled": "Si l’envoi multiple a été activé, le participant pourra envoyer plusieurs fois son profil en saisissant à nouveau le code campagne. Au sein de Uniscieléval, seul le dernier profil envoyé sera affiché.",
            "text-multiple-sendings-enabled": "Le participant peut envoyer plusieurs fois son profil en saisissant à nouveau le code campagne. Au sein de Uniscieléval, vous trouverez le dernier profil envoyé."
          }
        },
        "personalised-test-title": "Titre du parcours",
        "target-profile": "Profil cible",
        "title": "Paramètres"
      },
      "campaigns-list": {
        "action": {
          "archived": {
            "aria-label": "Afficher les campagnes archivées",
            "label": "Archivées"
          },
          "create": "Créer une campagne",
          "ongoing": {
            "aria-label": "Afficher les campagnes actives",
            "label": "Actives"
          }
        },
        "no-campaign": {
          "description": "Configurez et lancez votre première campagne. Vous pourrez ensuite inviter vos utilisateurs à rejoindre ce parcours.",
          "title": "Créez votre première campagne"
        },
        "table": {
          "column": {
            "campaign": "Campagnes",
            "created-by": "Créé par",
            "created-on": "Créé le",
            "participants": "Participants",
            "results": "Résultats reçus"
          },
          "empty": "Aucune campagne",
          "filter": {
            "by-creator": "Rechercher un créateur",
            "by-name": "Rechercher une campagne"
          },
          "row-title": "Campagne"
        },
        "title": {
          "active": "Campagnes actives",
          "archived": "Campagnes archivées"
        }
      },
      "certifications": {
        "description": "Sélectionnez la classe pour laquelle vous souhaitez exporter les résultats de certification (.csv) ou télécharger les attestations (.pdf).<br> Vous pouvez filtrer cette liste en renseignant le nom de la classe directement dans le champ.",
        "documentation-link": "https://cloud.pix.fr/s/cRaeKT4ErrXs4X8",
        "documentation-link-label": "Interprétation des résultats.",
        "documentation-link-notice": "Vous trouverez en suivant ce lien quelques éléments pour interpréter les résultats : ",
        "download-attestations-button": "Télécharger les attestations",
        "download-button": "Exporter les résultats",
        "errors": {
          "invalid-division": "La classe {selectedDivision} n'existe pas.",
          "no-certificates": "Aucune attestation de certification pour la classe {selectedDivision}.",
          "no-results": "Aucun résultat de certification pour la classe {selectedDivision}."
        },
        "select-label": "Classe",
        "title": "Résultats de certification"
      },
      "login": {
        "title": "Connectez-vous"
      },
      "login-form": {
        "active-or-retrieve": "Activez ou récupérez votre espace Uniscieléval",
        "email": "Adresse e-mail",
        "errors": {
          "status": {
            "401": "L'adresse e-mail et/ou le mot de passe saisis sont incorrects.",
            "401-should-change-password": "Erreur, vous devez changer votre mot de passe.",
            "403": "L'accès à Uniscieléval est limité aux membres invités. Chaque espace est géré par un administrateur Uniscieléval propre à l'organisation qui l'utilise. Contactez-le pour qu'il vous y invite."
          }
        },
        "forgot-password": "Mot de passe oublié ?",
        "forgotten-password-url": "https://app.pix.fr/mot-de-passe-oublie",
        "hide-password": "Masquer le mot de passe",
        "invitation-already-accepted": "Cette invitation a déjà été acceptée. Connectez-vous ou contactez l'administrateur de votre espace Uniscieléval.",
        "invitation-was-cancelled": "Cette invitation n’est plus valide. Contactez l’administrateur de votre espace Uniscieléval.",
        "is-only-accessible": "L'accès à Uniscieléval est limité aux membres invités. Contactez l’administrateur Uniscieléval de votre organisation pour qu'il vous invite.",
        "login": "Je me connecte",
        "mandatory-all-fields": "Tous les champs sont obligatoires.",
        "only-for-admin": "Réservé aux personnels de direction des établissements scolaires publics et privés sous contrat.",
        "password": "Mot de passe",
        "show-password": "Afficher le mot de passe"
      },
      "login-or-register": {
        "login-form": {
          "button": "Se connecter",
          "title": "J'ai déjà un compte"
        },
        "register-form": {
          "button": "S'inscrire",
          "fields": {
            "button": {
              "label": "Je m'inscris"
            },
            "cgu": {
              "accept": "J'accepte les",
              "aria-label": "Accepter les conditions d'utilisation de Unisciel",
              "error": "Vous devez accepter les conditions d’utilisation de Unisciel pour créer un compte.",
              "link": "https://pix.fr/conditions-generales-d-utilisation",
              "terms-of-use": "conditions d'utilisation de Unisciel"
            },
            "email": {
              "error": "Votre adresse e-mail n’est pas valide.",
              "label": "Adresse e-mail"
            },
            "first-name": {
              "error": "Votre prénom n’est pas renseigné.",
              "label": "Prénom"
            },
            "last-name": {
              "error": "Votre nom n’est pas renseigné.",
              "label": "Nom"
            },
            "password": {
              "error": "Votre mot de passe doit contenir 8 caractères au minimum et comporter au moins une majuscule, une minuscule et un chiffre.",
              "label": "Mot de passe"
            }
          },
          "legal-text": "Les informations recueillies sur ce formulaire sont enregistrées dans un fichier informatisé par Unisciel pour permettre l’accès au service offert. Elles sont conservées pendant la durée d’utilisation du service et sont destinées à Unisciel. Les résultats des tests pourront être communiqués à des tiers, avec votre consentement, dans le cas où vous avez été invité à suivre un parcours spécifique. Conformément à la loi « Informatique et Libertés », vous pouvez exercer votre droit d'accès aux données vous concernant et les faire rectifier en contactant le Délégué à la Protection des Données de Unisciel à dpo@Unisciel.fr.",
          "title": "Je m’inscris"
        },
        "title": "Vous êtes invité(e) à rejoindre l'organisation {organizationName}"
      },
      "preselect-target-profile": {
        "title": "Sélection des sujets"
      },
      "profiles-individual-results": {
        "certifiable": "Certifiable",
        "competences-certifiables": "COMP. CERTIFIABLES",
        "pix": "Unisciel",
        "pix-score": "{score, number}",
        "table": {
          "column": {
            "level": "Niveau",
            "pix-score": "Score Unisciel",
            "skill": "Compétence"
          },
          "empty": "En attente de résultats",
          "row-title": "Compétence",
          "title": "Résultats par compétence"
        },
        "title": "Profil de {firstName} {lastName}"
      },
      "profiles-list": {
        "table": {
          "column": {
            "certifiable": "Certifiable",
            "competences-certifiables": "Comp. certifiables",
            "first-name": "Prénom",
            "last-name": "Nom",
            "pix-score": {
              "label": "Score Unisciel",
              "value": "{score, number}"
            },
            "sending-date": {
              "label": "Date d'envoi",
              "on-hold": "En attente d'envoi"
            }
          },
          "empty": "En attente de profils",
          "row-title": "Profil",
          "title": "Liste des profils reçus"
        },
        "title": "Profils reçus"
      },
      "students-sco": {
        "actions": {
          "import-file": {
            "file-type-separator": " ou ",
            "label": "Importer ({types})"
          },
          "manage-account": "Gérer le compte",
          "show-actions": "Afficher les actions"
        },
        "connection-types": {
          "email": "Adresse e-mail",
          "empty": "–",
          "identifiant": "Identifiant",
          "mediacentre": "Mediacentre",
          "none": "Aucune"
        },
        "import": {
          "error-wrapper": "<div>Aucun élève n’a été importé.<br/><strong>{message}</strong><br/> Veuillez vérifier ou modifier votre base élèves et importer à nouveau.</div>",
          "global-error": "<div>Aucun élève n’a été importé.<br/>Veuillez réessayer ou nous contacter via <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"https://support.pix.fr/support/tickets/new\">le formulaire du centre d’aide</a>.</div>",
          "global-success": "La liste a été importée avec succès."
        },
        "manage-authentication-method-modal": {
          "authentication-methods": "Méthodes de connexion",
          "copied": "Copié !",
          "error": {
            "default": "Une erreur interne est survenue, nos équipes sont en train de résoudre le problème. Veuillez réessayer ultérieurement.",
            "unexpected": "Quelque chose s'est mal passé. Veuillez réessayer."
          },
          "section": {
            "add-username": {
              "button": "Ajouter l’identifiant",
              "label": "Ajouter une connexion avec un identifiant"
            },
            "email": {
              "copy": "Copier l’adresse e-mail",
              "label": "Adresse e-mail"
            },
            "mediacentre": {
              "label": "Médiacentre"
            },
            "password": {
              "copy": "Copier le mot de passe unique",
              "label": "Nouveau mot de passe à usage unique",
              "warning-1": "Communiquez ce nouveau mot de passe à votre élève.",
              "warning-2": "L’élève se connecte avec ce mot de passe à usage unique.",
              "warning-3": "Unisciel lui demande d'en choisir un nouveau."
            },
            "reset-password": {
              "button": "Réinitialiser le mot de passe",
              "warning": "Réinitialiser supprime le mot de passe actuel de l’élève."
            },
            "username": {
              "copy": "Copier l’identifiant",
              "label": "Identifiant"
            }
          },
          "title": "Gestion du compte Unisciel de l’élève"
        },
        "table": {
          "column": {
            "date-of-birth": "Date de naissance",
            "division": "Classe",
            "first-name": "Prénom",
            "last-name": "Nom",
            "login-method": "Méthode(s) de connexion"
          },
          "empty": "Aucun élève.",
          "filter": {
            "division": {
              "placeholder": "Rechercher par classe"
            },
            "first-name": {
              "aria-label": "Entrer un prénom",
              "label": "Rechercher par prénom"
            },
            "last-name": {
              "aria-label": "Entrer un nom",
              "label": "Rechercher par nom"
            },
            "login-method": {
              "aria-label": "Rechercher par méthode de connexion",
              "empty-option": "Tous"
            }
          },
          "row-title": "Élève"
        },
        "title": "Élèves"
      },
      "students-sup": {
        "actions": {
          "download-template": "Télécharger le modèle",
          "edit-student-number": "Éditer le numéro étudiant",
          "import-file": "Importer",
          "show-actions": "Afficher les actions"
        },
        "edit-student-number-modal": {
          "actions": {
            "update": "Mettre à jour"
          },
          "form": {
            "error": "Le numéro étudiant ne doit pas être vide.",
            "new-student-number-label": "Nouveau numéro étudiant",
            "student-number": "Numéro étudiant actuel de {firstName} {lastName} est : ",
            "success": "La modification du numéro étudiant de {firstName} {lastName} a bien été effectué."
          },
          "title": "Édition du numéro étudiant"
        },
        "table": {
          "column": {
            "date-of-birth": "Date de naissance",
            "first-name": "Prénom",
            "group": "Groupes",
            "last-name": "Nom",
            "student-number": "Numéro étudiant"
          },
          "empty": "Aucun étudiant.",
          "filter": {
            "first-name": {
              "aria-label": "Entrer un prénom",
              "label": "Rechercher par prénom"
            },
            "group": {
              "aria-label": "Entrer un groupe",
              "label": "Rechercher par groupe",
              "selected": "Groupes: {groups}"
            },
            "last-name": {
              "aria-label": "Entrer un nom",
              "label": "Rechercher par nom"
            },
            "student-number": {
              "aria-label": "Entrer un numéro étudiant",
              "label": "Rechercher par numéro étudiant"
            }
          },
          "row-title": "Étudiant"
        },
        "title": "Étudiants"
      },
      "students-sup-import": {
        "actions": {
          "add": {
            "details": "Permet d’ajouter une liste d'étudiants à celle déjà importée et/ou modifier les informations des étudiants déjà importés.",
            "label": "Ajouter des étudiants",
            "title": "Ajouter / modifier"
          },
          "replace": {
            "details": "Permet d’importer une nouvelle liste d'étudiants à la place de l’existante.",
            "label": "Importer une nouvelle liste",
            "title": "Remplacer"
          }
        },
        "description": "Quel type d'import souhaitez-vous réaliser ?",
        "error-wrapper": "<div>Aucun étudiant n’a été importé.<br/><strong>{message}</strong><br/> Veuillez modifier votre fichier et l’importer à nouveau.</div>",
        "global-error": "<div>Aucun étudiant n’a été importé.<br/>Veuillez réessayer ou nous contacter via <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"https://support.pix.fr/support/tickets/new\">le formulaire du centre d’aide</a></div>",
        "global-success": "La liste a été importée avec succès.",
        "global-success-with-warnings": "<div>La liste a été importée avec succès.<br/><br/>Cependant les valeurs suivantes n’ont pas été reconnues.<br />{warnings}<br/><br/>Elles ont été remplacées par “Non reconnu”. Si vous considérez qu’il manque des valeurs dans la liste limitative, veuillez nous contacter à <a href=\"mailto:sup@pix.fr\">sup@pix.fr</a></div>",
        "supported-formats": "(Format supporté : csv)",
        "title": "Importer des étudiants",
        "warnings": {
          "diploma": "Diplômes non reconnus : {diplomas}; ",
          "study-scheme": "Régimes non reconnus : {studySchemes}; "
        }
      },
      "team-invitations": {
        "cancel-invitation": "Supprimer l’invitation",
        "invitation-cancelled-succeed-message": "L’invitation a bien été supprimée.",
        "table": {
          "column": {
            "email-address": "Adresse e-mail",
            "pending-invitation": "Date de dernier envoi"
          },
          "row": {
            "aria-label": "Invitation en attente",
            "message": "Dernière invitation envoyée le"
          }
        },
        "title": "Invitations"
      },
      "team-list": {
        "add-member-button": "Inviter un membre",
        "tabs": {
          "invitation": "Invitations ({count, plural, =0 {-} other {{count}}})",
          "member": "Membres ({count, plural, =0 {-} other {{count}}})"
        },
        "title": "Mon équipe"
      },
      "team-members": {
        "actions": {
          "edit-organization-membership-role": "Modifier le rôle",
          "manage": "Gérer",
          "remove-membership": "Supprimer",
          "save": "Enregistrer",
          "select-role": {
            "label": "Sélectionner un rôle",
            "options": {
              "admin": "Administrateur",
              "member": "Membre"
            }
          }
        },
        "notifications": {
          "change-member-role": {
            "error": "Une erreur est survenue lors de la modification du rôle du membre.",
            "success": "Le rôle a bien été changé."
          },
          "remove-membership": {
            "error": "Une erreur est survenue lors de la désactivation du membre.",
            "success": "{memberFirstName} {memberLastName} a été supprimé avec succès de votre équipe Uniscieléval."
          }
        },
        "remove-membership-modal": {
          "actions": {
            "remove": "Oui, supprimer le membre"
          },
          "message": "{memberFirstName} {memberLastName} n'aura plus accès à cet espace Uniscieléval.<br>Ses campagnes restent accessibles au reste de l'équipe.",
          "title": "Confirmez-vous la suppression ?"
        },
        "table": {
          "column": {
            "first-name": "Prénom",
            "last-name": "Nom",
            "organization-membership-role": "Rôle"
          },
          "empty": "En attente de membres",
          "row-title": "Membre"
        },
        "title": "Membres"
      },
      "team-new": {
        "email-requirement": "Saisissez ici l'adresse e-mail du membre que vous souhaitez inviter.",
        "errors": {
          "default": "Le service est momentanément indisponible. Veuillez réessayer ultérieurement.",
          "status": {
            "400": "Le format de l'adresse e-mail est incorrect.",
            "404": "Cet email n'appartient à aucun utilisateur.",
            "412": "Ce membre a déjà été ajouté.",
            "500": "Quelque chose s'est mal passé. Veuillez réessayer."
          }
        },
        "invited-members": "À la réception de l'e-mail, les invités pourront choisir de se créer un compte Unisciel ou de se connecter avec un compte Unisciel existant.",
        "several-email-requirement": "Vous pouvez inviter plusieurs membres en séparant les adresses e-mails par des virgules.",
        "success": {
          "invitation": "Une invitation a bien été envoyée à l’adresse e-mail {email}.",
          "multiple-invitations": "Une invitation a bien été envoyée aux adresses e-mails listées."
        },
        "title": "Inviter un membre"
      },
      "team-new-item": {
        "input-label": "Adresse(s) e-mail",
        "invite-button": "Inviter"
      },
      "terms-of-service": {
        "accept": "J’accepte les conditions d’utilisation",
        "title": "Conditions d'utilisation"
      }
    }
  }]];
  _exports.default = _default;
});